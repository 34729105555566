<template>
  <div style="height: inherit">

    <div
      class="email-app-list position-relative"
    >

      <!-- Listagem -->
      <vue-perfect-scrollbar
        ref="reports"
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >
        <ul
          class="email-media-list"
        >
          <li
            v-for="report in reportsCanView"
            :key="`reports-${report.key}`"
          >
            <b-media
              tag="li"
              no-body
              @click.stop.prevent="getReport(report.key); $emit('close-left-sidebar');"
            >
              <b-media-body>
                <div class="mail-details mb-0">
                  <div class="mail-items mb-0">
                    <h5 class="mb-0">
                      {{ report.txt }}
                    </h5>
                  </div>
                </div>
              </b-media-body>
            </b-media>
          </li>
        </ul>
      </vue-perfect-scrollbar>

    </div>
  </div>
</template>

<script>
import store from '@/store'
import {
  VBTooltip, BMedia, BMediaBody,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import powerbiModule from '@store-modules/growth/powerbi/index'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'
import { configSettingsScroll } from '@core-custom/utils/ui'

export default {
  components: {
    BMedia,
    BMediaBody,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('powerbi', ['reports']),
    ...mapGetters('auth', ['currentUser']),
    isDark() {
      return this.skin.value === 'dark'
    },
    reportsCanView() {
      const result = []

      Object.keys(this.reports).forEach(key => {
        if (this.reports[key].perfis.includes(this.currentUser.id_role.toString()) || Number(this.currentUser.id_role) === 1) {
          result.push({
            key,
            txt: this.reports[key].txt || '',
          })
        }
      })

      return result
    },
  },
  watch: {
  },
  beforeDestroy() {
    if (this.$refs.listviewScroll) {
      this.$refs.listviewScroll.destroy()
    }
  },
  mounted() {
    if (useCapacitor.isNative() === true) {
      document.body.classList.add('capacitor')
    }
  },
  destroyed() {
    if (useCapacitor.isNative() === true) {
      document.body.classList.remove('capacitor')
    }
  },
  async created() {
    try {
      this.$root.$emit('app::loading', true)

      await store.dispatch('powerbi/getReports', { idReport: '' }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    } catch (err) {
      //
    }
  },
  methods: {
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    getReport(idReport) {
      const urlPrevRoute = `/powerbi/reportsBI/${idReport}`
      this.$router.push({ path: urlPrevRoute }).then(() => {
        this.$root.$emit('app::loading', false)
      })
    },
  },
  setup() {
    const POWERBI_MODULE_NAME = 'powerbi'

    if (!store.hasModule(POWERBI_MODULE_NAME)) {
      store.registerModule(POWERBI_MODULE_NAME, powerbiModule)

      onUnmounted(() => {
        if (store.hasModule(POWERBI_MODULE_NAME)) store.unregisterModule(POWERBI_MODULE_NAME)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
/* Desativa as animação devido que provoca erro no scroll to refresh */
.email-user-list ::v-deep{
  .email-media-list li {
    animation:none!important;
    animation-fill-mode:none!important;
  }
}

.email-user-list {
  height: 100%!important;
}
</style>
